import type { UiManagerState, UiPermissions, UiSettings, UiVisibility, UiStyles} from '../managers/UiManager';

import { ArticleNoInSubInfo, EnableMetatypeProperties, ManufacturerInSubInfo, SeriesInSubInfo, showArticleName } from '../DefaultValues';
import { PropertyEditorVariant, ArticleDrawerVariant } from '../managers/UiManager';

import { getConfig } from '@egr/gatekeeper-lib/lib/config';
import { USER_LANGUAGES } from '@egr/xbox/base-app/i18n';
import { DATA_LANGUAGES } from '@egr/xbox/base-app/managers/SessionManager';
import { withDebugTimer } from '@egr/xbox/utils/Debug';
import { getEnvBoolean, getEnvNumber, getEnvVar, getEnvValue } from '@egr/xbox/utils/ReactScriptHelper';
import { inIframe } from '@egr/xbox/utils/UserAgent';

import { contains } from '@easterngraphics/wcf/modules/utils/array';
import { ajax } from '@easterngraphics/wcf/modules/utils/async';
import { loadStyleSheet } from '@easterngraphics/wcf/modules/utils/dom';
import { isNotNullOrEmpty, isNullOrEmpty } from '@easterngraphics/wcf/modules/utils/string';

export async function loadUiSettings(gatekeeperId: string | undefined): Promise<UiManagerState | undefined> {
    try {
        if (isNullOrEmpty(gatekeeperId)) {
            return undefined;
        }
        let settingsId: string = gatekeeperId;
        if (settingsId.indexOf(':') !== -1) {
            settingsId = settingsId.split(':')[0];
        }
        const uiManagerState: UiManagerState = await withDebugTimer<UiManagerState>(
            'ui settings request',
            (): Promise<UiManagerState> => {
                return getConfig<UiManagerState>(getEnvVar('SETTINGS_FOLDER', 'pCon.ui'), settingsId);
            }
        );

        const state = await getUIManagerStateWithOverrides(uiManagerState);

        state.styles = await initializeStyles(uiManagerState.styles);

        return state;
    } catch (error) {
        return undefined;
    }
}

async function initializeStyles(styles: UiManagerState['styles']): Promise<UiManagerState['styles']> {
    const paletteImport = styles.paletteImport;

    if (isNotNullOrEmpty(paletteImport)) {
        try {
            const paletteOverride: UiManagerState['styles']['paletteOverride'] = await withDebugTimer<UiManagerState['styles']['paletteOverride']>(
                'load palette json',
                (): Promise<UiManagerState['styles']['paletteOverride']> => {
                    return ajax<UiManagerState['styles']['paletteOverride']>('GET', paletteImport);
                }
            );
            styles.paletteOverride = paletteOverride;
        } catch (e) {
            console.warn('palette import failed');
        }
    }

    if (inIframe()) {
        const cssImport: string | undefined = styles.cssImport;
        if (isNotNullOrEmpty(cssImport)) {
            try {
                await loadStyleSheet(cssImport);
            } catch (e) {
                console.warn('css import failed');
            }
        }
    }

    return styles;
}

function getUISettings(state: UiManagerState): UiManagerState['settings'] {
    const settings: UiSettings = state.settings;

    let lang: string = getEnvVar('lang', settings.general.lang ?? 'en');
    if (lang === 'auto') {
        lang = navigator.language ? navigator.language.substring(0, 2) : 'en';
    }

    settings.general.lang = contains(USER_LANGUAGES, lang) ? lang : 'en';

    const ofmlLang: string = getEnvVar('ofmlLang', settings.general.ofmlLang ?? settings.general.lang);
    settings.general.ofmlLang = contains(DATA_LANGUAGES, ofmlLang) ? ofmlLang : settings.general.lang;

    if (!state.permissions.pricing) {
        settings.pricing.showPrice = false;
    } else {
        settings.pricing.showPrice = getEnvBoolean('sp', true) !== getEnvBoolean('sp', false) ? settings.pricing.showPrice : getEnvBoolean('sp', true);
    }

    settings.general.fullscreenOnly = getEnvBoolean('fso', settings.general.fullscreenOnly);
    settings.general.generateContactRequestPdf = getEnvBoolean(
        'gpdf',
        settings.general.generateContactRequestPdf ?? settings.general.generateContactRequestPdf
    );

    if (isNotNullOrEmpty(settings.general.propertyDefinitionUrl)) {
        settings.general.propertyServiceUrl = getEnvVar('DEFAULT_PROPSERVICE_URL');
    }

    settings.pricing.tax = getEnvVar('tax', settings.pricing.tax);

    if (isNotNullOrEmpty(settings.pricing.priceServiceUrl)) {
        settings.pricing.calculationScheme = 'STD_PUI';
    }

    // configuration camera settings
    settings.camera.lockToTarget = getEnvBoolean('ltt', settings.camera.lockToTarget);
    settings.camera.restrictRotationToZAxis = getEnvBoolean('rrz', settings.camera.restrictRotationToZAxis);
    const cameraAlpha: number | undefined = getEnvNumber('alpha');
    const cameraBeta: number | undefined = getEnvNumber('beta');
    const cameraFov: number | undefined = getEnvNumber('fov');

    if (cameraAlpha != null && cameraBeta != null) {
        settings.camera.alpha = cameraAlpha;
        settings.camera.beta = cameraBeta;
        settings.camera.fov = cameraFov;
    }

    return settings;
}

async function getUIManagerStateWithOverrides(state: UiManagerState): Promise<UiManagerState> {
    const permissions: UiPermissions = state.permissions;
    const visibility: UiVisibility = state.visibility;
    const styles: UiStyles = state.styles;

    state.defaults = getDefaults(state);
    state.settings = getUISettings(state);

    visibility.general.header = getEnvBoolean('sh', visibility.general.header);

    if (permissions.iframeAllowedParents == null) {
        permissions.iframeAllowedParents = [];
    }
    permissions.iframeAllowedParents.push('*.localhost', 'ui.pcon-solutions.com', 'cd.easterngraphics.com');
    permissions.iframeAllowedParents = permissions.iframeAllowedParents.map((value) => {
        return value.toLocaleLowerCase();
    });

    // configuration layout
    const backgroundFallback: UiStyles['background'] = getEnvBoolean('rb', false) ? 'radiantgrey' : styles.background;
    styles.background = getEnvVar('sb', backgroundFallback) as UiStyles['background'];

    styles.articleDrawerVariant = getEnvValue<ArticleDrawerVariant>(
        'adv',
        [ArticleDrawerVariant.SINGLE, ArticleDrawerVariant.MODULAR],
        styles.articleDrawerVariant ?? ArticleDrawerVariant.SINGLE
    );
    styles.propertyEditorVariant = getEnvValue<PropertyEditorVariant>(
        'pev',
        [PropertyEditorVariant.FULL, PropertyEditorVariant.COMPACT],
        styles.propertyEditorVariant ?? PropertyEditorVariant.FULL
    );
    visibility.articleData = getArticleData(visibility);

    state.permissions = permissions;
    state.visibility = visibility;
    return state;
}

function getDefaults(uiManagerState: UiManagerState): UiManagerState['defaults'] {
    const { settings, visibility, permissions } = uiManagerState;

    return {
        lang: settings.general.lang,
        fullscreenOnly: settings.general.fullscreenOnly,
        showPrice: settings.pricing.showPrice && permissions.pricing,
        netPrice: settings.pricing.netPrice,
        header: visibility.general.header,
        lockToTarget: settings.camera.lockToTarget,
        restrictRotationToZAxis: settings.camera.restrictRotationToZAxis,
        description: true,
        manufacturerInSubInfo: visibility.articleData.manufacturerInSubInfo,
        seriesInSubInfo: visibility.articleData.seriesInSubInfo,
        articleNoInSubInfo: visibility.articleData.articleNoInSubInfo,
        showArticleName: visibility.articleData.showArticleName,
        showPropertyHeaders: visibility.articleData.showPropertyHeaders,
        showUneditableProperties: visibility.articleData.showUneditableProperties,
        enableMetatypeProperties: visibility.articleData.enableMetatypeProperties
    };
}

function getArticleData(visibility: UiVisibility): UiVisibility['articleData'] {
    const articleData = visibility.articleData;
    articleData.showPropertyHeaders = getEnvBoolean('sph', visibility.articleData.showPropertyHeaders);
    articleData.showUneditableProperties = getEnvBoolean('sup', visibility.articleData.showUneditableProperties);
    articleData.description = !getEnvBoolean('hde' /* hide description */, !visibility.articleData.description);

    articleData.manufacturerInSubInfo = getEnvBoolean(
        'msi',
        visibility.articleData.manufacturerInSubInfo ?? ManufacturerInSubInfo
    );

    articleData.seriesInSubInfo = getEnvBoolean('ssi', visibility.articleData.seriesInSubInfo ?? SeriesInSubInfo);
    articleData.articleNoInSubInfo = getEnvBoolean('asi', visibility.articleData.articleNoInSubInfo ?? ArticleNoInSubInfo);
    articleData.showArticleName = getEnvBoolean('san', visibility.articleData.showArticleName ?? showArticleName);

    articleData.enableMetatypeProperties = getEnvBoolean(
        'emp',
        articleData.enableMetatypeProperties ?? EnableMetatypeProperties
    );

    return articleData;
}

export type ButtonTypes =
    'dimension' | 'snapshot' | 'fullscreen' | 'iso' | 'cad' | 'pdf' | 'interactors' | 'ar' |
    'viewFront' | 'viewTop' | 'viewLeft' | 'viewRight';